export class Passenger {
  id?: string;
  fname: string;
  lname: string;
  email: string;
  phoneNumber: string;
  type: string;
  companyId?: string;
  stripeCustomerId?: string;
  paymentMethodId?: string;
  debtorId?: string;
  realm?: string;
  addressId?: string;
  created?: Date;
  modified?: Date;
  isDeleted: boolean;
  debtorType?: string;
  displayName?: string;
  connectionId?: string;
  lastLogin?: Date;
  branding?: { name: string; logo: any, sendAccountEmail: boolean, url: string };
  language?: string;
  portalAccess?: any;
}
