<ng-container *tdLoading="loaderName">
  <mat-card tdMediaToggle="gt-xs">
    <mat-card-title [className]="'mat-card-title filter'">
      {{'customer'|translate|ucFirst}}<span *ngIf="debtor">: {{debtor.companyName || '-'}}</span>

      <button (click)="deleteDebtor()" class='delete-debtor' color="warn" mat-raised-button>
        <mat-icon>delete</mat-icon>
        {{'delete_debtor'|translate|ucFirst}}
      </button>

      <button (click)="editDebtor()" [className]="'mat-raised-button mat-primary tool'" color="primary"
              mat-raised-button>
        <mat-icon>edit</mat-icon>
        {{'edit_debtor'|translate|ucFirst}}
      </button>
    </mat-card-title>
    <mat-card-subtitle>{{'debtor_details_subtitle'|translate}}</mat-card-subtitle>
    <mat-divider></mat-divider>
    <div *ngIf="debtor" class="inset" layout="column" layout-gt-sm="row">
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list>
            <mat-list-item>
              <mat-icon matListAvatar>business</mat-icon>
              <h4 matLine>{{debtor.companyName || '-'}}</h4>
              <p matLine>{{'debtor_company'|translate|ucFirst}}</p>
            </mat-list-item>
            <mat-list *ngIf="debtor.note">
              <mat-list-item class="pull-top">
                <mat-icon matListAvatar>assignment</mat-icon>
                <h4 matLine>{{debtor.note || '-'}}</h4>
                <p matLine>{{'note'|translate}}</p>
              </mat-list-item>
            </mat-list>
          </mat-list>
        </div>
      </div>
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list-item>
            <mat-icon matListAvatar>fingerprint</mat-icon>
            <h4 matLine>{{debtor.code || '-'}}</h4>
            <p matLine>{{'customer_code'|translate|ucFirst}}</p>
          </mat-list-item>

        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
<app-passenger-list *ngIf="context==='company' && debtor" [companyId]="companyId"
                    [debtorId]="debtor.id"></app-passenger-list>

<mat-card tdMediaToggle="gt-xs">
  <mat-card-title [className]="'mat-card-title filter'">
    {{'invoices'|translate|ucFirst}}
  </mat-card-title>
  <app-open-invoices #debtorInvoices *ngIf="debtor"
                     [companyId]="companyId"
                     [context]="'company'"
                     [debtorId]="debtor.id"
                     [invoiceView]="'Debtor'"
                     [type]="'history'">
  </app-open-invoices>
</mat-card>
