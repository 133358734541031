<div [formGroup]="timeframe">
  <div class="date-time-container">
    <mat-form-field
      [ngStyle]="{'width': specificWeekDays() ? '' : '100%'}"
      class="date-input">
      <input [matDatepicker]="startPicker"
             [max]="timeframe.value.endDate"
             formControlName="startDate"
             matInput
             placeholder="{{ 'start_date' | translate | ucFirst }}">
      <mat-datepicker-toggle [for]="startPicker" disabled="false" matSuffix>
      </mat-datepicker-toggle>
      <mat-datepicker #startPicker [startAt]="startDate" startView="year">
      </mat-datepicker>
    </mat-form-field>
    <mat-form-field
      *ngIf="specificWeekDays()"
      class="push-right time-input">
      <input (change)="timeInputChange('start', $event)"
             [disabled]="!specificWeekDays()"
             [value]="startTime"
             matInput
             placeholder="{{ 'start_time' | translate | ucFirst }}"
             type="time">
    </mat-form-field>
  </div>

  <div class="date-time-container">
    <mat-form-field
      [ngStyle]="{'width': specificWeekDays() ? '' : '100%'}"
      class="date-input">
      <input [matDatepicker]="endPicker"
             [min]="timeframe.value.startDate"
             formControlName="endDate"
             matInput
             placeholder="{{ 'end_date' | translate | ucFirst }}">
      <mat-datepicker-toggle [for]="endPicker" disabled="false" matSuffix>
      </mat-datepicker-toggle>
      <mat-datepicker #endPicker [startAt]="endDate" startView="year">
      </mat-datepicker>
    </mat-form-field>
    <mat-form-field
      *ngIf="specificWeekDays()"
      class="push-right time-input">
      <input (change)="timeInputChange('end', $event)"
             [disabled]="!specificWeekDays()"
             [value]="endTime"
             matInput
             placeholder="{{ 'end_time' | translate | ucFirst }}"
             type="time">
    </mat-form-field>
  </div>

  &ensp;
  <mat-checkbox #isSpecificWeekDays formControlName="isSpecificWeekDays">
    {{ 'specific_week_days' | translate | ucFirst }}
  </mat-checkbox>

  <div *ngIf="!specificWeekDays()" class="scrollable">

    <br>

    <mat-card-subtitle>
      {{ 'timeframes_subtitle' | translate | ucFirst}}
    </mat-card-subtitle>

    <table class="noselect timeframe-table">
      <thead>
      <tr>
        <th></th>
        <th *ngFor="let hour of hours" style="font-size: xx-small;">
          {{ hour }}-{{ hour + 1}}
        </th>
      </tr>
      <tr>
        <th aria-hidden="true" class="" colspan="7"></th>
      </tr>
      </thead>
      <tbody role="grid">

      <tr *ngFor="let day of days; let dayI = index"
          class="timeframe-table-row"
          role="row">
        <td [attr.aria-label]="day | ucFirst"
            class="timeframe-body-cell"
            style="text-align: left;">
          {{ day.substr(0, 3) | ucFirst }}&ensp;
        </td>
        <td *ngFor="let hour of hours; let hourI = index"
            [ngClass]="{'timeframe-body-selected': getCharAt(dayI * 24 + hourI) !== '0'}"
            class="timeframe-body-cell"
            style="cursor: pointer;">
          <label class="timeframe-body-cell-content"
                 style="cursor: pointer; font-size: xx-small;">
            <mat-checkbox (change)="toggleCharAt(dayI * 24 + hourI)"
                          [checked]="getCharAt(dayI * 24 + hourI)">
            </mat-checkbox>
          </label>
        </td>
      </tr>

      </tbody>
    </table>
  </div>
  <br>
</div>
