import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent, TdPagingBarComponent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ActivatedRoute, Router} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Ride} from '../../../models/ride';
import {RideService} from 'app/services/ride.service';
import {UtilityService} from '../../../services/utility.service';
import {DriverService} from '../../../services/driver.service';
import {ExporterService} from '../../../services/exporter.service';
import moment from 'moment';
import {NavigationService} from '../../../services/navigation.service';
import {DispatchDialogComponent} from '../dispatchpanel/parts/dispatch-dialog/dispatch-dialog.component';
import {Company} from '../../../models/company';
import {TdDialogService} from '@covalent/core/dialogs';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BusinessLicenseComponent} from '../../global/business-license/business-license.component';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-rides',
  templateUrl: './rides.component.html',
  styleUrls: ['./rides.component.scss'],
  providers: [RideService, ExporterService]
})
export class RidesComponent extends BusinessLicenseComponent implements OnInit, AfterViewInit {
  @ViewChild('sentRidesPagingBar') sentRidesPagingBar: TdPagingBarComponent;

  context: string;
  companyId: string;
  startRow = 0;

  meAsDriverColumns: any = [
    {name: 'owner', label: 'owner', sortable: true, active: false},
    {name: 'reference', label: 'reference', sortable: true, active: false, fixedSize: 'w-80'},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: true},
    {name: 'departure', label: 'departure', sortable: true, active: false, maxWidth: 'max-width-300'},
    {name: 'status', label: 'status', sortable: true, active: false},
    {name: 'driverPrice', label: 'driver_price', sortable: true, active: false},
    {name: 'driverSettlement', label: 'driver_settlement', sortable: true, active: false},
    {name: 'paymentOrigin', label: 'payment_method', sortable: true, active: false, fixedSize: 'w-150'}
  ];
  meAsOwnerColumns: any = [
    {name: 'reference', label: 'reference', sortable: true, active: false, fixedSize: 'w-80'},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: true},
    {name: 'departure', label: 'departure', sortable: true, active: false, maxWidth: 'max-width-300'},
    {name: 'status', label: 'status', sortable: true, active: false},
    {name: 'fareAmount', label: 'fare_amount', sortable: true, active: false},
    {name: 'paymentOrigin', label: 'payment_method', sortable: true, active: false, maxSize: 'maxSize1024', fixedSize: 'w-150'},
    {name: 'commissionAmount', label: 'commission_amount', sortable: true, active: false, maxSize: 'maxSize1440'},
    {name: 'driver', label: 'driver', sortable: true, active: false, maxSize: 'maxSize1024'},
  ];

  meAsDriverExportColumns: any = [
    {name: 'owner', label: 'owner'},
    {name: 'driver', label: 'driver'},
    {name: 'reference', label: 'reference'},
    {name: 'requestedDate', label: 'requested_date'},
    {name: 'passengerCount', label: 'passengers'},
    {name: 'departure', label: 'departure'},
    {name: 'destination', label: 'destination'},
    {name: 'status', label: 'status'},
    {name: 'passengerName', label: 'passenger'},
    {name: 'paymentOrigin', label: 'payment_method'},
    {name: 'calculationMethod', label: 'calculation_method'},
    {name: 'exportDriverPrice', label: 'driver_price'},
    {name: 'exportDriverSettlement', label: 'driver_settlement'},
    {name: 'exportFareCurrency', label: 'fare_currency'},
    {name: 'driverNote', label: 'driver_note'},
    {name: 'operatorNote', label: 'operator_note'},
  ];

  meAsOwnerExportColumns: any = [
    {name: 'driver', label: 'driver'},
    {name: 'reference', label: 'reference'},
    {name: 'requestedDate', label: 'requested_date'},
    {name: 'passengerCount', label: 'passengers'},
    {name: 'departure', label: 'departure'},
    {name: 'destination', label: 'destination'},
    {name: 'status', label: 'statusText'},
    {name: 'passengerName', label: 'passenger'},
    {name: 'paymentOrigin', label: 'payment_method'},
    {name: 'calculationMethod', label: 'calculation_method'},
    {name: 'exportRoutePrice', label: 'route_amount'},
    {name: 'exportAdditionalPrice', label: 'additional_amount'},
    {name: 'exportTotalPrice', label: 'fare_amount'},
    {name: 'exportCommissionAmount', label: 'commission_amount'},
    {name: 'exportDriverPrice', label: 'driver_price'},
    {name: 'exportOwnerDriverSettlement', label: 'driver_settlement'},
    {name: 'driverNote', label: 'driver_note'},
    {name: 'operatorNote', label: 'operator_note'},
    {name: 'debtorCode', label: 'debtor_code'},
    {name: 'debtorName', label: 'debtor_name'},
    {name: 'debtorEmail', label: 'debtor_email'},
    {name: 'debtorPhone', label: 'debtor_phone'},
  ];
  loaderName = 'rides.list';
  translations: any[] = [];

  driver: any;
  departureGps: any;
  reference: string;
  rideId: string;
  company: Company;
  sub: any;
  activeTab = 'my-jobs';
  selectedTabIndex = 0;
  filterDates: any = {
    'from': null,
    'to': null
  };
  rideOrigins: any[];

  searchTerm = '';

  ridesAsDriver: any = {
    data: [],
    filteredData: [],
    filteredTotal: 0,
    fromRow: 1,
    pageSize: 100,
    currentPage: 1,
    sortBy: 'requestedDateTime',
    sortOrder: TdDataTableSortingOrder.Descending,
    excludedSearchColumns: [
      'passengerName',
      'paymentDescription',
      'paymentMethod',
      'driver',
    ]
  };

  ridesAsOwner: any = {
    data: [],
    filteredData: [],
    filteredTotal: 0,
    fromRow: 1,
    pageSize: 100,
    currentPage: 1,
    sortBy: 'requestedDateTime',
    sortOrder: TdDataTableSortingOrder.Descending,
    excludedSearchColumns: [
      'passengerName',
      'paymentDescription',
      'paymentMethod',
      'owner',
    ]
  };

  defaultFilterStatus = 'all';
  todoFilterStatus = ['unassigned', 'assigned', 'driving_to_pickup', 'arrived_at_pickup', 'started', 'arrived_at_destination'];
  defaultFilterFromDate: Date;
  filterRideStatus = `${this.defaultFilterStatus}`;
  rideStatuses: string[] = ['todo', 'canceled', 'completed'];
  driverIds: string[] = [];
  dataSub: Subscription;

  constructor(public media: TdMediaService,
              private _route: ActivatedRoute,
              private _router: Router,
              public snackBar: MatSnackBar,
              private _vault: CoolLocalStorage,
              private _loadingService: TdLoadingService,
              private _dataTableService: TdDataTableService,
              private _dialogService: TdDialogService,
              private _rideService: RideService,
              private _navigationService: NavigationService,
              private _exporter: ExporterService,
              private _driverService: DriverService,
              private _translateService: TranslateService,
              private _titleService: Title) {
    super();
    this.context = this._route.routeConfig['context'] || 'driver';

    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    const defaultFromDate = new Date();
    const storedDate = this._vault.getItem(`${environment.vaultPrefix}.ridesFromFilter`);
    if (storedDate) {
      this.defaultFilterFromDate = moment(storedDate).toDate();
      this.filterDates.from = storedDate;
    } else {
      defaultFromDate.setMonth(defaultFromDate.getMonth() - 1);
      this.defaultFilterFromDate = defaultFromDate;
      this.filterDates.from = defaultFromDate;
    }

    const storedToDate = this._vault.getItem(`${environment.vaultPrefix}.ridesToFilter`);
    if (storedToDate) {
      this.filterDates.to = moment(storedToDate).toDate();
      ;
    }

    _translateService.get([
      'unconfirmed', 'pickup_date', 'reference', 'my_jobs', 'my_rides', 'list_rides_as_driver', 'list_rides_as_owner', 'sent_rides', 'ride_id', 'requested_date', 'passengers', 'departure', 'destination', 'driver', 'status', 'company', 'origin', 'passenger', 'debtor_number', 'debtor_name', 'debtor_code', 'debtor_email', 'debtor_phone', 'payment_method', 'owner', 'calculation_method', 'fare_amount', 'total_amount', 'commission_amount', 'commission_currency', 'driver_note', 'operator_note', 'payment_origin_inTaxi', 'payment_origin_inApp', 'payment_origin_online', 'payment_origin_invoice', 'fare_currency', 'route_amount', 'additional_amount', 'driver_price', 'driver_settlement', 'company_jobs', 'jobs'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;

      if (this.context === 'driver') {
        this._titleService.setTitle(translations['my_jobs'] + environment.windowTitleSuffix);
        this._navigationService.setBodyTitle(translations['my_jobs']);
      } else {
        this._titleService.setTitle(translations['company_jobs']);
        this._navigationService.setBodyTitle(translations['company_jobs']);
        this.setBusinessContract(_vault);
      }
    });
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
  }

  ngOnInit() {
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    this._navigationService.setActionLink('');

    this.sub = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
    });
  }

  ngAfterViewInit() {
    this.loadData();
    this.media.broadcast();
  }

  //noinspection JSMethodCanBeStatic
  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.activeTab = 'planned';
    }
    if (event.index === 1) {
      this.activeTab = 'completed';
    }
    if (event.index === 2) {
      this.activeTab = 'canceled';
    }
  }

  sortTableData(sortEvent: ITdDataTableSortChangeEvent, dataSet: string): void {
    this[dataSet].sortBy = sortEvent.name;
    this[dataSet].sortOrder = sortEvent.order;
    if (sortEvent.name === 'requestedDate') {
      this[dataSet].sortBy = 'requestedDateTime';
    }
    this.filterTableData(dataSet);
  }

  pageTableData(pagingEvent: IPageChangeEvent, dataSet: string): void {
    // this[dataSet].fromRow = pagingEvent.fromRow;
    // this[dataSet].currentPage = pagingEvent.page;
    // this[dataSet].pageSize = pagingEvent.pageSize;
    // this.filterTableData(dataSet);
    this.startRow = pagingEvent.fromRow;
    this.loadData();
  }

  filterTableData(dataSet: string): void {
    const self = this;
    console.groupCollapsed(`filterTableData(${dataSet})`);
    let filteredData: any[] = [...this[dataSet].data];

    // if (this.searchTerm && this.searchTerm !== '') {
    //   filteredData = this._dataTableService.filterData(filteredData, this.searchTerm.trim(), true, this[dataSet].excludedSearchColumns);
    // }

    if (this.filterRideStatus === 'todo') {
      filteredData = filteredData.filter((ride) => {
        return (self.todoFilterStatus.includes(ride.status));
      });
    } else if (this.filterRideStatus !== 'all') {
      filteredData = filteredData.filter(ride => ride.status === this.filterRideStatus);
    }

    // this[dataSet].filteredTotal = filteredData.length;

    filteredData = this._dataTableService.sortData(filteredData, this[dataSet].sortBy, this[dataSet].sortOrder);
    filteredData = this._dataTableService.pageData(
      filteredData,
      this[dataSet].fromRow, this[dataSet].currentPage * this[dataSet].pageSize
    );

    this[dataSet].filteredData = [...filteredData];
    console.log('dataSet:', this[dataSet]);
    // console.log('status', this.filterRideStatus);
    // console.log('data', this[dataSet].data);
    console.log('filteredData', filteredData);
    console.groupEnd();
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.loadData();
    // this.filterTableData('ridesAsDriver');
    // this.filterTableData('ridesAsOwner');
  }

  rideFilterChanged(data: any): void {
    // console.log(`[RidesComponent.rideFilterChanged]:`, data);
    if (data.filterSearchTerm !== this.searchTerm) {
      this.searchTerm = data.filterSearchTerm;
      this['ridesAsDriver'].fromRow = 0;
      this['ridesAsOwner'].fromRow = 0;
      this.startRow = 0;
      //this.sentRidesPagingBar.setRange(1);
    }

    if (data.filterStatus !== '') {
      this.filterRideStatus = data.filterStatus;
    }
    if (data.filterDates.from !== '') {
      this.onFromFilterChanged(data.filterDates.from);
    }

    if (data.filterDates.to !== '') {
      this.onToFilterChanged(data.filterDates.to);
    }
    this.loadData();
  }

  onStatusFilterChanged(status: string): void {
    this.filterRideStatus = status;
  };

  onFromFilterChanged(date: Date): void {
    this._vault.setItem(`${environment.vaultPrefix}.ridesFromFilter`, date.toISOString());
    this.filterDates.from = date;
  }

  onToFilterChanged(date: Date): void {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    this.filterDates.to = date;
    this._vault.setItem(`${environment.vaultPrefix}.ridesToFilter`, date.toISOString());
  }

  ngOnDestroy(): void {
    this.dataSub.unsubscribe();
    this._navigationService.setActionFn(null);
    this._navigationService.setActionLink('');
    this._navigationService.setInfoAction('');
  }

  export() {
    const data = [
      {
        title: 'list_rides_as_driver',
        headers: this.meAsDriverExportColumns,
        rows: this.ridesAsDriver.filteredData
      },
      {
        title: `list_rides_as_owner`,
        headers: this.meAsOwnerExportColumns,
        rows: this.ridesAsOwner.filteredData
      },
    ];

    let exportData = [];

    if (this.context === 'company') {
      /**
       * Remove first element of array
       */
      data.shift();
      data[0].title = 'jobs';
      /**
       * Owner	Driver	Reference	Requested time	Passengers	Pick-up address	Drop-off address	Status	Passenger	Payment method	Calculation method	Route amount	Additional amount	Fare amount	Fare currency	Commission amount	Driver price	Driver settlement	Commission currency	Driver note	Dispatcher note	Customer code	Debtor name	Customer email	Customer phone
       */

      if (this.context === 'company') {
        /**
         * Remove first element of array
         */
        exportData = [
          {
            title: 'jobs',
            headers: this.meAsOwnerExportColumns,
            colWidths: this.meAsOwnerExportColumns.map((column) => {
              // Check for text length and set width based on it
              const maxLength = this.ridesAsOwner.filteredData.reduce((max, ride) => {
                const fieldValue = String(ride[column.label] || ''); // Convert to string if not already
                return Math.max(max, fieldValue.length);
              }, column.label.length);  // Start with label length to ensure the header fits

              // Default width for text fields and a little larger for longer text
              return { wch: maxLength + 5 };  // Add extra space for a comfortable view
            }),
            colFormats: this.meAsOwnerExportColumns.map((column) => {
              // Check if the column represents a monetary value (we assume if the label contains 'amount' or 'price', it's a currency field)
              if (column.label.toLowerCase().includes('amount') || column.label.toLowerCase().includes('price')) {
                return `"${this.company.currency} "#,##0.00`;  // Format with currency and two decimals
              }
              if (column.name === 'requestedDate') {
                return '@';  // '@' in Excel format forces the value to be treated as text
              }
              return '';  // No formatting for non-currency fields
            }),
            rows: this.ridesAsOwner.filteredData.map((ride) => {
              return this.meAsOwnerExportColumns.reduce((acc, column) => {
                const fieldValue = ride[column.label];

                if (column.name === 'requestedDate') {
                  // Format the requestedDate as a string in the desired format (e.g., 'YYYY-MM-DD HH:mm')
                  acc[column.label] = moment(fieldValue).format('YYYY-MM-DD HH:mm');
                } else if (typeof fieldValue === 'number') {
                  // Handle number fields (e.g., currency or amounts)
                  acc[column.label] = (fieldValue / 100).toFixed(2); // Example for currency in cents
                } else {
                  // For everything else, just pass it as is
                  acc[column.label] = fieldValue || '';
                }

                return acc;
              }, {});
            })
          },
        ];


        console.log(exportData);
        this._exporter.generate(`${this.company.name.toLowerCase().replace(/\ /g, '_')}`, this.translations, exportData);
      }
    }
  }

  queryData(status: string, start: number): Observable<any> {
    let ownerId, ownerType;
    if (this.context === 'company') {
      ownerId = this.company.id;
      ownerType = 'Company';
    } else {
      ownerId = this.driver.id;
      ownerType = 'Driver';
    }
    let transStatus = status;
    this.filterDates.from = moment(this.filterDates.from);
    if (this.filterDates.to) {
      this.filterDates.to = moment(this.filterDates.to);
    }

    this._rideService.portalCount(ownerId, ownerType, status, this.filterDates.from.toISOString(), (this.filterDates.to ? this.filterDates.to.toISOString() : ''), this.searchTerm).subscribe((result: any) => {
      this.ridesAsOwner[`filteredTotal`] = (result[0] && result[0].status ? result[0].status : '0');
    });

    return this._rideService.portalSearch(ownerId, ownerType, status, start, 100, this.filterDates.from.toISOString(), (this.filterDates.to ? this.filterDates.to.toISOString() : ''), this.searchTerm);
  }

  loadData(): void {
    const self = this;
    let status = this.filterRideStatus;
    self._loadingService.register(self.loaderName);
    console.log('register:', self.loaderName);

    this.dataSub = this.queryData(status, this.startRow).subscribe((rides: any[]) => {
      this.rideOrigins = [];
      this.ridesAsDriver.data = [];
      this.ridesAsOwner.data = [];

      if (rides.length > 0) {
        for (let i = 0; i < rides.length; i++) {
          rides[i].requestedDateTime = new Date(rides[i].requestedDate).getTime();
          this.createTableRide(rides[i], false);

          rides[i].requestedDateTimeFormat = moment(rides[i].requestedDate).format('YYYY-MM-DD HH:ii');
        }
      }

      this.selectedTabIndex = 0;

      this.filterTableData('ridesAsOwner');
      self._loadingService.resolve(self.loaderName);
      // self._loadingService.resolveAll();
      console.log('Resolve:', self.loaderName);
    });
  }

  createTableRide(ride: Ride, isOwner: boolean): any {
    // if (!this.rideStatuses.includes(ride.status)) {
    //   this.rideStatuses.push(ride.status);
    // }
    const id = (ride.foreignDispatchId ?
      ride.foreignDispatchId :
      (ride.foreignBookingId ?
        ride.foreignBookingId :
        ride.intId));

    const passenger = (ride.Passenger ? ride.Passenger[0] : ride.passenger);
    const reference = ride.reference;

    let owner = '';
    if (ride.Owner && ride.ownerType === 'Driver') {
      owner = `${ride.Owner.fname} ${ride.Owner.lname}`;
    } else if (ride.Owner && ride.ownerType === 'Company') {
      owner = `${ride.Owner.name}`;
    }

    let driver = '';
    if (ride.Driver && ride.Driver[0]) {
      driver = `${ride.Driver[0].fname} ${ride.Driver[0].lname}`;
    }

    const tableRide = {
      ...ride,
      owner,
      driver,
      reference,
      id: (ride.id ? ride.id : ride['_id']),
      intId: id,
      foreignDispatchId: ride.foreignDispatchId,
      requestedDate: new Date(ride.requestedDate),
      pickupDate: (typeof ride.pickupDate !== 'undefined' ? new Date(ride.pickupDate) : ''),
      passengerCount: ride.passengerCount,
      departure: UtilityService.getLocationString(ride.departure),
      destination: UtilityService.getLocationString(ride.destination),
      status: ride.status,
      statusText: ucFirst(this._translateService.instant(`ride_status_${ride.status}`)),
      passengerName: (passenger ? `${passenger.fname} ${passenger.lname}` : ''),
      passengerPhone: (passenger ? passenger.phoneNumber : ''),
      passengerEmail: (passenger ? passenger.emailAddress : ''),
      paymentMethod: (ride.paymentMeta ? ride.paymentMeta.origin : ''),
      paymentDescription: (ride.paymentMeta ? ride.paymentMeta.description : ''),
      paymentOrigin: (ride.paymentMeta ? this.translations[`payment_origin_${ride.paymentMeta.origin}`] : ''),
      calculationMethod: ride.priceMethod || '',
      fareAmount: (ride.price ? ride.price : 0),
      exportFareAmount: `${(ride.price ? ride.price.total / 100 : 0)}`.replace('.', ','),
      currency: (ride.price ? ride.price.currency : 'EUR'),
      exportFareCurrency: (ride.price ? ride.price.currency : 'EUR'),
      commissionAmount: (ride.commission ? ride.commission : 0),
      exportCommissionAmount: `${(ride.commission ? ride.commission.total / 100 : 0)}`.replace('.', ','),
      commissionCurrency: (ride.commission ? ride.commission.currency : 0),
      exportRoutePrice: `${(ride.price && ride.price.breakdown && ride.price.breakdown.route ?
        ride.price.breakdown.route.total / 100 :
        0)}`.replace('.', ','),
      exportAdditionalPrice: `${(ride.price && ride.price.breakdown && ride.price.breakdown.additional ?
        ride.price.breakdown.additional.total / 100 :
        0) + (ride.price && ride.price.breakdown && ride.price.breakdown.cleaning ?
        ride.price.breakdown.cleaning.total / 100 :
        0)}`.replace('.', ','),
      exportTotalPrice: `${(ride.price ? ride.price.total / 100 : 0)}`.replace('.', ','),
      exportDriverPrice: `${(ride.driverPrice ? ride.driverPrice.total / 100 : 0)}`.replace('.', ','),
      ownerDriverSettlement: `${{
        ...ride.driverSettlement, total: (ride.driverSettlement ?
          (0 - ride.driverSettlement.total) / 100 :
          0)
      }}`.replace('.', ','),
      exportOwnerDriverSettlement: `${(ride.driverSettlement ?
        (0 - ride.driverSettlement.total) / 100 :
        0)}`.replace('.', ','),
      exportDriverSettlement: `${(ride.driverSettlement ?
        ride.driverSettlement.total / 100 :
        0)}`.replace('.', ','),
      debtorCode: (ride.Debtor[0] ? ride.Debtor[0].code : 0),
      debtorName: (ride.Debtor && ride.Debtor[0] ?
        `${ride.Debtor[0].firstName} ${ride.Debtor[0].lastName} ${(ride.Debtor[0].companyName ? `(${ride.Debtor[0].companyName}` : '')}` :
        ''),
      debtorEmail: (ride.Debtor && ride.Debtor[0] ? ride.Debtor[0].emailAddress : ''),
      debtorPhone: (ride.Debtor && ride.Debtor[0] ? ride.Debtor[0].phoneNumber : ''),
    };

    if (this.context === 'driver') {
      if (ride.Owner && this.driverIds.includes(ride.ownerId) && isOwner) {
        this.ridesAsOwner.data.push(tableRide);
      }
      if (ride.Owner && this.driverIds.includes(ride.driverId) && !isOwner) {
        this.ridesAsDriver.data.push(tableRide);
      }
    } else {
      this.ridesAsOwner.data.push(tableRide);
    }
  }

  open(id): void {
    if (this.context === 'company') {
      this._router.navigate([`/groups/${this.companyId}/jobs/${id}/details`]);
    } else {
      this._router.navigate([`/personal/jobs/${id}/details`]);
    }
  }

  openEdit(id, route): void {
    if (this.context === 'company') {
      if (!route) {
        this._router.navigate([`/groups/${this.companyId}/jobs/${id}`]);
      }
      this._router.navigate([`/groups/${this.companyId}/jobs/${id}/${route}`]);
    } else {
      if (!route) {
        this._router.navigate([`/personal/dispatchpanel/${id}`]);
      }
      this._router.navigate([`/personal/dispatchpanel/${id}/${route}`]);
    }
  }

  /**
   * Open Pricing Rule Dialog.
   */
  openDispatchDialog(id) {
    const self = this;
    this._rideService.get(id, {}).subscribe((ride: Ride) => {
      self.departureGps = ride.departure.gps;
      self.reference = ride.reference;
      self.rideId = ride.id;
      this._dialogService.open(DispatchDialogComponent, {
        maxHeight: '80%',
        maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
        data: {
          parent: this,
        },
      });
    });
  }

  cancelJob(rideId): void {
    const self = this;
    this._rideService.cancelJob(rideId, {'status': 'canceled'}, 'company').subscribe(() => {
      setTimeout(function () {
        self._loadingService.resolve(self.loaderName);
        self.snackBar.open(ucFirst(self.translations['job_canceled']), 'OK', {
          duration: 3000
        });
        self._loadingService.register(self.loaderName);
        self.loadData();
      }, 500);
    }, error => {
      console.error(error);
      self._loadingService.resolve(self.loaderName);
    })
  }
}
