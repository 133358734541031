import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {Company} from '../../../../models/company';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {Title} from '@angular/platform-browser';
import {UtilityService} from '../../../../services/utility.service';
import {environment} from '../../../../../environments/environment';
import {MessageAutomationRuleService} from '../../../../services/message-automation-rule.service';
import {MessageCenter} from '../../../../models/message-center';
import {AddNewMessageComponent} from './parts/add-new-message/add-new-message.component';
import moment from 'moment';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CompanyService} from '../../../../services/company.service';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent extends CollectionComponent implements OnInit, OnDestroy {
  loaderName = 'job-message-automation-rule';
  modelName = 'JobMessageAutomationRule';
  company: Company;
  settingsForm: UntypedFormGroup;
  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'recipientType', label: 'recipient', sortable: true, active: true},
    {name: 'messageTypes', label: 'message_types', sortable: true, active: true},
    {name: 'active', label: 'active', sortable: true, active: true},
    {name: 'tools', label: 'tools', sortable: true, active: true}
  ];
  selectFilter = {
    where: {
      companyId: ''
    }
  }

  constructor(private _translateService: TranslateService,
              protected injector: Injector,
              private _messageAutomationRuleService: MessageAutomationRuleService,
              private _companyService: CompanyService,
              private _snackbar: MatSnackBar,
              private _formBuilder: UntypedFormBuilder,
              private _dialogService: TdDialogService,
              private _titleService: Title) {
    super(injector);
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;
    this.selectFilter.where.companyId = this.company.id;
    this.modelService = this._messageAutomationRuleService;

    this.originalData = {
      published: [],
      archived: [],
    }
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      _translateService.get(['message_center_page_title', 'job_automationrule_archived', 'job_automationrule_published', 'job_automationrule_deleted', 'message_center_rule_added_message', 'sms', 'email', 'yes', 'ok', 'no', 'active', 'confirm_delete_message_action', 'confirm_disable_message_action_saved', 'confirm_disable_message_action']).subscribe((translations: any) => {
        this._titleService.setTitle(translations['message_center_page_title'] + environment.windowTitleSuffix);
        this._navigationService.setBodyTitle(translations['message_center_page_title']);
        this.translations = translations;
      });
    });
    this.settingsForm = this._formBuilder.group({
      sentDefaultConfirmation: [(this.company.settings.sentDefaultConfirmation !== 'disabled' ? this.company.settings.sentDefaultConfirmation : false), []],
      sentDefaultCompletion: [(this.company.settings.sentDefaultCompletion !== 'disabled' ? this.company.settings.sentDefaultCompletion : false), []],
      sentDefaultCancelation: [(this.company.settings.sentDefaultCancelation !== 'disabled' ? this.company.settings.sentDefaultCancelation : false), []],
      sentDefaultDriverAssigned: [(this.company.settings.sentDefaultDriverAssigned !== 'disabled' ? this.company.settings.sentDefaultDriverAssigned : false), []],
      smsSender: [(this.company.settings.smsSender ? this.company.settings.smsSender : this.company.name.substring(0, 10)), []],
    }, {validators: []});
  }

  setLink(name: string) {
    const self = this;
    if (!this.link) {
      return;
    }
    this._navigationService.setActionLink('message-center/JobMessageAutomationRule/add');
    // this._navigationService.setActionFn(function() {
    //   self.addNewMessage();
    // });
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
    this._navigationService.setActionFn(null);
  }

  addNewMessage() {
    this._dialogService.open(AddNewMessageComponent, {
      maxHeight: '90%',
      height: 'auto',
      maxWidth: (window.innerWidth < 600 ? '100%' : '800px'),
      data: {
        company: this.company,
        parent: this,
        collectionData: this.collectionData,
      }
    });
  }

  openEdit($event, itemId) {
    if ($event && (
      ($event.target && $event.target.localName === 'mat-icon' || $event.target.localName === 'button') ||
      ($event.srcElement && $event.srcElement.localName === 'mat-icon' || $event.srcElement.localName === 'button')
    )) {
      return false;
    }
    this._router.navigate([`/groups/${this.companyId}/message-center/${this.modelName}/${itemId}/edit`]);
  }
  loadData = () => {
    const self = this;
    if (!self.translations) {
      setTimeout(function() {
        self.loadData()
      }, 300);
      return;
    }
    const query = {
      'where': {
        'companyId': this.companyId
      },
      'order': 'created ASC'
    };

    self.modelService.getAll(query, 'company').subscribe((jobAutomationRules: MessageCenter[]) => {
      // const self = this;
      self.collectionData = [];
      self.filteredData = [];

      jobAutomationRules.forEach((promotion: MessageCenter, i) => {
        const duplicates = [];
        promotion._actions = promotion._actions.filter((a) => {
          if (!duplicates.includes(a.actionType)) {
            duplicates.push(a.actionType);
            return a;
          }
        });

        promotion['messageTypes'] = promotion._actions.map((a) => {
          duplicates.push(self.translations[a.actionType]);
          return self.translations[a.actionType];
        }).join(', ')

        promotion['active'] = (promotion.dateLimit ? self.translations['active'] : moment(promotion.startDate).format(this.company.dateFormat) + ' / ' + moment(promotion.endDate).format(this.company.dateFormat));

        self.collectionData.push(promotion);
      });
      self.originalData['published'] = self.collectionData.filter((d) => {
        return (d.status === 'published')
      })
      self.originalData['archived'] = self.collectionData.filter((d) => {
        return (d.status === 'archived')
      });

      self.filter('published');
      self.filter('archived');
      self._loadingService.resolve(this.actionName);
    }, (error => {
      console.error(error);
      self._loadingService.resolve(this.actionName);
    }));
  }

  /**
   * Archive a message automation rule by updating its status to 'archived'.
   * Display a snackbar message and reload the data after archiving.
   *
   * @param id - The ID of the message automation rule to archive.
   */
  archive(id: string) {
    // Update the status of the message automation rule to 'archived'
    this._messageAutomationRuleService.update(id, {status: 'archived'})
      .subscribe((r) => {
        // Display a snackbar message to indicate that the rule has been archived
        this._snackbar.open(this.translations['job_automationrule_archived'], '', {duration: 3000});

        // Reload the data after archiving
        this.loadData();
      });
  }

  publish(id: string) {
    const self = this;
    self._messageAutomationRuleService.update(id, {status: 'published'}, 'company').subscribe((r) => {
      self._snackbar.open(this.translations['job_automationrule_published'], '', {duration: 3000});
      self.loadData();
    });
  }

  delete(id: string) {
    const self = this;
    /**
     * Confirm
     */
    self._dialogService.openConfirm({
      'message': self._translateService.instant('confirm_delete_job_automationrule'),
      'disableClose': false,
      'acceptButton': self._translateService.instant('delete'),
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._messageAutomationRuleService.delete(id, 'company').subscribe((r) => {
            self._snackbar.open(this.translations['job_automationrule_deleted'], '', {duration: 3000});
            self.loadData();
          });
        }
      });
  }

  saveSettings() {
    const self = this;
    self.company.settings.sentDefaultConfirmation = this.settingsForm.controls.sentDefaultConfirmation.value;
    self.company.settings.sentDefaultCompletion = (this.settingsForm.controls.sentDefaultCompletion.value ? this.settingsForm.controls.sentDefaultCompletion.value : 'disabled');
    self.company.settings.sentDefaultDriverAssigned = this.settingsForm.controls.sentDefaultDriverAssigned.value;
    self.company.settings.smsSender = this.settingsForm.controls.smsSender.value;

    if (!this.settingsForm.controls.sentDefaultConfirmation.value) {
      self._dialogService.openConfirm({
        'message': self.translations['confirm_disable_message_action'],
        'disableClose': false,
        'acceptButton': self._translateService.instant('save_settings'),
      }).afterClosed()
        .subscribe((accept: boolean) => {
          if (accept) {
            self.company.settings.sentDefaultConfirmation = 'disabled';
            self._companyService.update(self.company.id, {settings: self.company.settings}).subscribe(() => {
              self._snackbar.open(this.translations['confirm_disable_message_action_saved'], '', {duration: 3000});
            });
          } else {
            this.settingsForm.controls.sentDefaultConfirmation.setValue(true);
          }
        });
    } else {
      self.company.settings.sentDefaultConfirmation = this.settingsForm.controls.sentDefaultConfirmation.value;
      self._companyService.update(self.company.id, {settings: self.company.settings}).subscribe(() => {
        self._snackbar.open(this.translations['confirm_disable_message_action_saved'], '', {duration: 3000});
      });
    }
  }
}
