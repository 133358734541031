import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DebtorService} from '../../../services/debtor.service';
import {IPageChangeEvent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {NavigationService} from '../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Debtor} from '../../../models/debtor';
import {Company} from '../../../models/company';
import {UtilityService} from '../../../services/utility.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {CoolLocalStorage} from '@angular-cool/storage';
import {PassengerService} from '../../../services/passenger.service';
import {Passenger} from '../../../models/passenger';
import moment from 'moment-timezone';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';
import {DaAppInstallService} from '../../../services/da-app-install.service';
import {WebbookerService} from '../../../services/webbooker.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExporterService} from '../../../services/exporter.service';

@Component({
  selector: 'app-debtors',
  templateUrl: './debtors.component.html',
  styleUrls: ['./debtors.component.scss'],
  providers: [DebtorService, PassengerService]
})
export class DebtorsComponent implements OnInit, OnDestroy {
  context: string;
  company: Company;
  loaderName = 'policy-list';
  dataSubscription: Subscription;
  routeSubscription: Subscription;
  companyId: string;

  debtors: Debtor[] = [];
  contacts: Passenger[] = [];
  filteredDebtors = {
    contacts: [],
    accounts: [],
  };
  totalRecords = {
    contacts: [],
    accounts: [],
  };
  filteredDebtorCount = 0;

  selectedTabIndex = 0;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'identifier';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  activeTab = 'accounts';
  accountsCount = 0;
  contactsCount = 0;

  columns = [
    {name: 'code', label: 'code', sortable: true, active: false},
    {name: 'firstName', label: 'first_name', sortable: true, active: true, maxSize: 'maxSize600'},
    {name: 'lastName', label: 'last_name', sortable: true, active: false},
    {name: 'companyName', label: 'debtor_company', sortable: true, active: false},
    {name: 'emailAddress', label: 'email', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false, maxSize: 'maxSize600'}
  ];

  contactColumns = [
    {name: 'fname', label: 'first_name', sortable: true, active: true, maxSize: 'maxSize600'},
    {name: 'lname', label: 'last_name', sortable: true, active: false},
    {name: 'email', label: 'email', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'portalAccess', label: 'portal_access', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'tools', label: 'tools', sortable: true, active: false, maxSize: 'maxSize600'},
  ];
  translations: string[] = [];

  constructor(private _debtorService: DebtorService,
              private _router: Router,
              private _vault: CoolLocalStorage,
              private _route: ActivatedRoute,
              private _dataTableService: TdDataTableService,
              private _loadingService: TdLoadingService,
              private _translateService: TranslateService,
              private _exporter: ExporterService,
              private _navigationService: NavigationService,
              private _dialogService: TdDialogService,
              private _daAppInstallService: DaAppInstallService,
              private _webbookerService: WebbookerService,
              private _snackBar: MatSnackBar,
              private _passengerService: PassengerService,
              private _titleService: Title) {

    let currentActiveTab = window.location.href.split('#')[1];
    if (!currentActiveTab) {
      currentActiveTab = this._route.snapshot.queryParams['return']
    }

    if (currentActiveTab) {
      if (currentActiveTab === 'contacts') {
        this.selectedTabIndex = 1;
      }
      this.activeTab = currentActiveTab;
    }

    this.routeSubscription = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      this.context = 'company';
      if (this.context === 'company') {
        const {company} = this._route.parent.snapshot.data;
        UtilityService.setBrowserTimeStamp(this.company);
        this.company = company;
      }
      _translateService.get([
        'group_customers',
        'code',
        'first_name',
        'last_name',
        'debtor_company',
        'email',
        'phone_number',
        'portal_access',
      ]).subscribe((translations: any) => {
        this._titleService.setTitle(translations['group_customers'] + environment.windowTitleSuffix);
        this._navigationService.setBodyTitle(translations['group_customers']);
        this._navigationService.setActionLink(`/groups/${this.companyId}/debtors/add`);
        this.translations = translations;
      });
      this.loadData();
    });
  }

  ngOnInit(): void {
    this._loadingService.register(this.loaderName);
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter(this.totalRecords[this.activeTab], this.activeTab);
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter(this.totalRecords[this.activeTab], this.activeTab);
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.activeTab = 'accounts';
      this._navigationService.setActionLink(`/groups/${this.companyId}/debtors/add`);
    } else if (event.index === 1) {
      this.activeTab = 'contacts';
      this._navigationService.setActionLink(`/groups/${this.companyId}/debtors/contacts/add`);
    }
    this._vault.setObject(`${environment.vaultPrefix}.contact.tab`, event.index);
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter(this.totalRecords[this.activeTab], this.activeTab);
  }

  filter(data: any[], debtorType: string): void {
    let filteredData: any[] = data;

    filteredData = this._dataTableService.filterData(filteredData, this.searchTerm, true);
    filteredData = this._dataTableService.sortData(filteredData, this.sortBy, this.sortOrder);
    filteredData = this._dataTableService.pageData(filteredData, this.fromRow, this.currentPage * this.pageSize);

    this.filteredDebtorCount = filteredData.length;
    this.filteredDebtors[debtorType] = filteredData;
  }

  open(id: string): void {
    if (id) {
      this._router.navigate([`/groups/${this.companyId}/debtors/${id}/view`]);
    }
  }

  openContact(id: string): void {
    if (id) {
      this._router.navigate([`/groups/${this.companyId}/contacts/${id}/view`]);
    }
  }

  loadData(): void {
    const self = this;
    const query = {where: {ownerId: this.companyId, ownerType: 'Company'}};
    this.dataSubscription = this._debtorService.getAll(query, 'company').subscribe((debtors: Debtor[]) => {
      this.debtors = debtors;
      this.totalRecords['accounts'] = this.debtors;
      this.filter(this.debtors, 'accounts');

      const queryPassenger = {where: {realm: this.companyId}};
      this.dataSubscription = this._passengerService.getAll(queryPassenger, 'company').subscribe((passengers: Passenger[]) => {
        this.contacts = passengers.map((passenger: Passenger) => {
          if (!passenger['inviteSend'] && passenger['lastLogin']) {
            passenger['inviteSend'] = passenger['lastLogin'];
          }

          passenger['fullName'] = passenger['firstName'] + ' ' + passenger['lastName'];
          passenger['portalAccess'] = (passenger['inviteSend'] ? moment(passenger['inviteSend']).toISOString() : false);
          return passenger;
        });
        this.totalRecords['contacts'] = this.contacts;
        this.filter(this.contacts, 'contacts');
        setTimeout(function () {
          self._loadingService.resolve(self.loaderName);
        }, 500);
      }, error => {
        console.error(error);
        this._loadingService.resolve(this.loaderName);
      })

      setTimeout(function () {
        self._loadingService.resolve(self.loaderName);
      }, 500);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  deleteContact($event, itemId, name): void {
    const self = this;
    const message = self._translateService.instant('message_delete_x').formatUnicorn(name);
    const title = self._translateService.instant('delete_x').formatUnicorn(name);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: ucFirst(self._translateService.instant('cancel')),
      acceptButton: ucFirst(self._translateService.instant('delete')),
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._passengerService.delete(itemId, 'portal').subscribe(() => {
          self._snackBar.open(self._translateService.instant('x_deleted').formatUnicorn(name), 'OK', {duration: 5000});
          self.loadData();
        });
      }
    });
  }

  sendContactEmail(resetToken: boolean, contact) {
    const self = this;
    self._daAppInstallService.getAll({
      where: {
        daAppId: environment.webbookerDaAppId,
        companyId: self.company.id,
      }
    }).subscribe((bookers) => {
      self._dialogService.openConfirm({
        message: self._translateService.instant('reset_passenger_account_email_message'),
        disableClose: false,
        title: self._translateService.instant('reset_passenger_account_email_message_title'),
        cancelButton: self._translateService.instant('cancel'),
        acceptButton: self._translateService.instant('send'),
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          self._loadingService.register(self.loaderName);

          const booker = bookers[0];
          if (booker.formId) {
            self._daAppInstallService.refreshToken(booker.formId)
              .then((token) => {
                self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
                  .subscribe((formDetails) => {
                    booker.form = formDetails;
                    self._passengerService.sendContactEmail({
                      email: contact.email,
                      companyId: self.company.id,
                      daAppInstallId: booker.id,
                      type: (resetToken ? 'reset' : 'user'),
                      branding: {
                        name: booker.form.name,
                        url: `${environment.orderFormFrontEndUrl}/dashboard/${contact.language}/${booker.form.id}/set-password`,
                        logo: booker.form.config.logo,
                      },
                      translationId: booker.form.config.translationId
                    })
                      .subscribe(() => {
                        self._snackBar.open(ucFirst(self._translateService.instant('email_message_sent').replace('{{email}}', contact.email)), self._translateService.instant('ok'), {
                          panelClass: 'snack-success',
                          duration: 3000
                        });
                        setTimeout(function () {
                          self._loadingService.resolve(self.loaderName);
                        }, 500);
                      });
                  });
              });

          } else {
            self._loadingService.resolve();
          }
        }
      });
    });
  }

  doExport() {
    /**
     * Get headers from data keys
     */
    const data = [
      {
        title: this._translateService.instant('debtors'),
        headers: this.columns,
        colWidths: [{ wch: 12 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 20 }],
        rows: this.debtors.map((d) => {
          return {
            'code': d.code,
            'firstName': d.firstName,
            'lastName': d.lastName,
            'companyName': d.companyName,
            'emailAddress': d.emailAddress,
            'phoneNumber': d.phoneNumber
          }
        }),
      },
      {
        title: this._translateService.instant('contacts'),
        headers: this.contactColumns.filter((c) =>{
          return c.name !== 'tools';
        }),
        rows: this.contacts.map((c: Passenger) => {
          return {
            'fname': c.fname,
            'lname': c.lname,
            'email': c.email,
            'phoneNumber': c.phoneNumber,
            'portalAccess': c.portalAccess,
          }
        }),
        colWidths: [{ wch: 20 }, { wch: 20 }, { wch: 40 }, { wch: 20 }, { wch: 12 }],
      }];

    this._exporter.generate(
      `customers_${this.company.name.toLowerCase().replace(/\ /g, '_')}`,
      this.translations,
      data);
  }
}
