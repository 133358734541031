import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {CollectionComponent} from '../../../../global/collection/collection.component';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {RideService} from '../../../../../services/ride.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MessagelogService} from '../../../../../services/messagelog.service';
import {RatingComponent} from '../../../../company/administration/rides/ride-details/parts/rating/rating.component';
import {MessageLogEmailDialogComponent} from './parts/message-log-email-dialog/message-log-email-dialog.component';

@Component({
  selector: 'app-ride-messagelog-data',
  templateUrl: './ride-messagelog-data.component.html',
  styleUrls: ['./ride-messagelog-data.component.scss']
})
export class RideMessagelogDataComponent extends CollectionComponent implements OnInit, OnDestroy {
  @Input() ride;
  translations: string[];
  actionName = 'messageLog';
  modelName = 'messageLog';
  columns: any[] = [
    {name: 'created', label: 'date', fixedSize: 'minSize150 w-100', sortable: true, active: false, maxSize: null},
    {name: 'type', label: 'type', sortable: true, active: false, maxSize: null},
    {name: 'to', label: 'to', sortable: true, active: false, maxSize: null},
    {name: 'description', label: 'description', sortable: true, active: false, maxSize: null},
  ];
  type = 'all';
  total = {
    total: 0,
    currency: ''
  };
  cTotal = {
    total: 0,
    currency: ''
  };
  even = 'odd';
  paging = {
    'all': {
      fromRow: 0,
      currentPage: 0,
      pageSize: 5
    }
  };
  selectFilter = {
    where: {
      'rideId': ''
    }
  };

  constructor(
    protected injector: Injector,
    private _translateService: TranslateService,
    private _titleService: Title,
    private _messagelogService: MessagelogService,
    private _activatedRoute: ActivatedRoute,
    private _injector: Injector,
    private _rideService: RideService,
    private _dialogService: TdDialogService,
    private _snackBar: MatSnackBar,
  ) {
    super(injector);
    this.modelService = _messagelogService;

    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    _translateService.get(['ok', 'payment_capture_confirm_message', 'payment_capture_confirm_title', 'payment_refund_confirm_message', 'payment_refund_confirm_title', 'payment_refunded_message', 'payment_processed_message', 'payment_record_delete_confirm_message', 'payment_record_delete_confirm_title']).subscribe((translations: any) => {
      this.translations = translations;
    });
  }
  _ngOnInit() {
    if (this.ride) {
      this.selectFilter.where.rideId = this.ride.id;
      this.originalData['all'] = this.ride.MessageLog.Entries;
      this.afterFilter();
    }
  }

  afterFilter() {
    this.total.total = 0;
    this.cTotal.total = 0;
    this.originalData['all'] = this.originalData['all'].filter((item) => {
      return (item.message);
    });
    this.originalData['all'].forEach((item) => {
      if (item.message) {
        item.type = item.message.type.toUpperCase();
        item.description = item.message.text;
        item.subject = item.message.title;
        item.to = item.message.to;
      }
    });
  }

  ngOnInit() {
    this._ngOnInit();
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    this._navigationService.setInfoAction('');
  }

  openEmail(html: any) {
    this._dialogService.open(MessageLogEmailDialogComponent, {
      maxHeight: '800px',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {
        parent: this,
        html: html,
      },
    });
  }
}
