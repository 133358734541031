import {Component, OnDestroy, OnInit} from '@angular/core';
import {Driver} from '../../../../models/driver';
import {Subscription} from 'rxjs/Rx';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {Passenger} from '../../../../models/passenger';
import {PassengerService} from '../../../../services/passenger.service';
import {PassengerPaymentmethodsComponent} from '../../dispatchpanel/parts/ride-edit/parts/ride-edit-passenger/parts/passenger-paymentmethods/passenger-paymentmethods.component';
import {TdDialogService} from '@covalent/core/dialogs';
import {PassengerAddPaymentmethodComponent} from '../../dispatchpanel/parts/ride-edit/parts/ride-edit-passenger/parts/passenger-add-paymentmethod/passenger-add-paymentmethod.component';
import {GlobalFunctions} from '../../../../functions/functions';
import {UtilityService} from '../../../../services/utility.service';
import {Company} from '../../../../models/company';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {WebbookerService} from '../../../../services/webbooker.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  providers: [PassengerService]
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  context: string;
  loaderName = 'debtor-details';
  driver: Driver;
  company: Company;
  dataSubscription: Subscription;
  passengerPayment: PassengerPaymentmethodsComponent;
  companyId: string;
  contact: Passenger = null;
  passenger: Passenger = null;
  translations: string[];

  constructor(private _passengerService: PassengerService,
              private loadingService: TdLoadingService,
              private vault: CoolLocalStorage,
              private router: Router,
              private _route: ActivatedRoute,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _dialogService: TdDialogService,
              private _daAppInstallService: DaAppInstallService,
              private _webbookerService: WebbookerService,
              private _router: Router,
              private titleService: Title,
              private _snackBar: MatSnackBar,
              public _globalFunctions: GlobalFunctions) {
    this.context = this._route.routeConfig['context'] || 'driver';
    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
    this.companyId = this._route.parent.snapshot.paramMap.get('id');
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    _translateService.get([
      'contact_details',
      'confirm_delete_payment_method_message',
      'confirm_delete_payment_method_title',
      'cancel',
      'delete',
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.titleService.setTitle(ucFirst(translations['contact_details'] + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(ucFirst(translations['contact_details']));
      this._navigationService.setSplitLayoutButtons([
        {
          'icon': 'list',
          'tooltip': translations['contact_details'],
          'link': `/groups/${this.companyId}/debtors/`
        }
      ]);
    });

    this.loadData();
  }

  ngOnInit(): void {
    this.loadingService.register(this.loaderName);
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this._navigationService.setActionLink('');
    this._navigationService.setSplitLayoutButtons([]);
  }

  loadData(): void {
    this.dataSubscription = this._passengerService.get(this._route.snapshot.paramMap.get('id'), {}, this.context).subscribe((contact: Passenger) => {
      this.contact = contact;
      console.log(this.contact);

      if (!this.contact.lastLogin) {
        this.contact.lastLogin = null;
      }

      this.passengerPayment = new PassengerPaymentmethodsComponent(
        this._passengerService,
        this._translateService,
        this._dialogService,
        this._route,
        this._globalFunctions,
      )

      this.passengerPayment.retrieveContactPaymentMethods(this.contact)

      const self = this;
      setTimeout(function () {
        self.loadingService.resolve(self.loaderName);
      }, 500);
    }, error => {
      console.error(error);
      this.loadingService.resolve(this.loaderName);
    })
  }

  retrieveContactPaymentMethods() {
    this.loadingService.register(this.loaderName);
    this.loadData();
  }

  editcontact(): void {
    if (this.context === 'driver') {
      this.router.navigate([`/contact/${this.contact.id}/edit`]);
    } else {
      this.router.navigate([`/groups/${this.companyId}/contacts/${this.contact.id}/edit`]);
    }
  }

  addPaymentMethod() {
    const self = this;
    const {company} = this._route.parent.snapshot.data
    self.passenger = self.contact;
    self._dialogService.open(PassengerAddPaymentmethodComponent, {
      maxHeight: '80%',
      maxWidth: '500px',
      minWidth: '500px',
      minHeight: '250px',
      data: {
        openFromDialog: true,
        company: company,
        parent: self,
      },
    });
  }

  deletePaymentMethod(item) {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_delete_payment_method_message'],
      disableClose: false,
      title: self.translations['confirm_delete_payment_method_title'],
      cancelButton: self.translations['cancel'],
      acceptButton: self.translations['delete'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        this.loadingService.register(this.loaderName);
        this._passengerService.deletePaymentMethod(this.contact.id, (item.id ? item.id : item.debtorId), item.type, 'company')
          .subscribe(() => {
            self.loadData();
            setTimeout(function () {
              self.loadingService.resolve(self.loaderName);
            }, 500);
          });
      }
    });

  }

  sendContactEmail(resetToken: boolean) {
    const self = this;
    self._daAppInstallService.getAll({
      where: {
        daAppId: environment.webbookerDaAppId,
        companyId: self.company.id,
      }
    }).subscribe((bookers) => {
      self._dialogService.openConfirm({
        message: self._translateService.instant('reset_passenger_account_email_message'),
        disableClose: false,
        title: self._translateService.instant('reset_passenger_account_email_message_title'),
        cancelButton: self.translations['cancel'],
        acceptButton: self._translateService.instant('send'),
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          self.loadingService.register(self.loaderName);

          const booker = bookers[0];
          if (booker.formId) {
            console.log(booker.formId);
            self._daAppInstallService.refreshToken(booker.formId)
              .then((token) => {
                self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
                  .subscribe((formDetails) => {
                    booker.form = formDetails;
                    self._passengerService.sendContactEmail({
                      email: self.contact.email,
                      companyId: self.company.id,
                      daAppInstallId: booker.id,
                      type: (resetToken ? 'reset' : 'user'),
                      branding: {
                        name: booker.form.name,
                        url: `${environment.orderFormFrontEndUrl}/dashboard/${self.contact.language}/${booker.form.id}/set-password`,
                        logo: booker.form.config.logo,
                      },
                      translationId: booker.form.config.translationId
                    })
                      .subscribe(() => {
                        self._snackBar.open(ucFirst(self._translateService.instant('email_message_sent').replace('{{email}}', self.contact.email)), self.translations['ok'], {
                          panelClass: 'snack-success',
                          duration: 3000
                        });
                        setTimeout(function () {
                          self.loadingService.resolve(self.loaderName);
                        }, 500);
                      });
                  });
              });

          } else {
            self.loadingService.resolve();
          }
        }
      });
    });
  }

  deleteCustomer() {
    const self = this;
    const name = `${self.contact.fname} ${self.contact.lname}`;
    const message = self._translateService.instant('message_delete_x').formatUnicorn(name);
    const title = self._translateService.instant('delete_x').formatUnicorn(name);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: self._translateService.instant('cancel'),
      acceptButton: self._translateService.instant('delete'),
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._passengerService.delete(self.contact.id, 'company').subscribe(() => {
          self._dialogService.openConfirm({
            message: self._translateService.instant('x_deleted').formatUnicorn(name),
            disableClose: true,
            acceptButton: self._translateService.instant('ok'),
          }).afterClosed().subscribe(() => {
            this._router.navigate([`/groups/${self.companyId}/debtors/`]);
          });
        });
      }
    });
  }
}
