<mat-card>
  <mat-list-item>
    <mat-icon mat-list-icon>message</mat-icon>
    <h4 mat-line>{{'messagelog'|translate|ucFirst}}</h4>
    <p mat-line>{{'messagelog_subtitle'|translate|ucFirst}}</p>
  </mat-list-item>

  <mat-divider></mat-divider>

  <table class="mat-clickable" td-data-table>
    <thead>
    <tr *ngIf="originalData['all'].length > 0" td-data-table-column-row>
      <th (sortChange)="sort($event)"
          *ngFor="let c of columns"
          [active]="(sortBy == c.name)"
          class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}}"
          [name]="c.name"
          [numeric]="c.numeric"
          [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
          [sortable]="c.sortable"
          td-data-table-column>
        {{c.label|translate|ucFirst}}
      </th>
    </tr>
    </thead>
    <tbody *ngIf="originalData['all'].length > 0">
    <ng-container *ngFor="let item of originalData['all'];" class="todo-assign">
      <tr class="pointer {{item.colorClass}} {{item.trColor}}"
          td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="c.numeric"
            class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}}"
            td-data-table-cell>
          <span [ngSwitch]="c.name">
             <span *ngSwitchCase="'created'">
                <span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY HH:mm')}}
                </span>
              </span>
              <span *ngSwitchCase="'description'" class="description">
                <span *ngIf="item[c.name] && item['type']==='SMS'">
                  {{item[c.name].substring(0, 100)}}{{(item[c.name].length > 100 ? '...' : '')}}
                  <mat-icon *ngIf="item[c.name] && item['note']" [matTooltip]="item['note']" class="info-icon">info</mat-icon>
                </span>
                <span *ngIf="item[c.name] && item['type']==='EMAIL'" (click)="openEmail(item[c.name])">
                  {{item['subject']}}
                </span>
              </span>
              <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                *ngIf="!item[c.name]">-</span></span>
          </span>
        </td>
      </tr>
    </ng-container>
    </tbody>
    <tbody *ngIf="originalData['all'].length === 0">
    <tr>
      <td [colSpan]="columns.length"
          class="table-placeholder">
        {{'no_messages_sent'|translate|ucFirst}}
      </td>
    </tr>
    </tbody>
  </table>

</mat-card>
