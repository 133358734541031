<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>

  <button (click)="doExport()" [className]="'mat-raised-button mat-primary tool export-button'" color="primary"
          mat-raised-button>
    {{'export'|translate|ucFirst}}
  </button>
  <mat-divider></mat-divider>
  <ng-template tdLoading="{{loaderName}}">
    <mat-tab-group (selectedTabChange)="onTabChange($event)"
                   [selectedIndex]="selectedTabIndex" animationDuration="300ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">group</mat-icon>
          {{'accounts'|translate|ucFirst}}
          <span *ngIf="!this.searchTerm">&nbsp;({{this.accountsCount}})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{this.allFilteredData['accounts'].length}}/{{this.accountsCount}})
          </span>
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="filteredDebtors.length > 0">
          <tr *ngFor="let item of filteredDebtors" td-data-table-row>
            <td (click)="(c.name!='tools' ? open(item.id) : false)" *ngFor="let c of columns" [numeric]="item.numeric"
                class="select {{(c.name=='tools'?'text-right':'')}}"
                td-data-table-cell>
            <span [ngSwitch]="c.name">
              <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                *ngIf="!item[c.name]">-</span></span>
            </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="filteredDebtors.length === 0">
          <tr>
            <td [colSpan]="columns.length"
                class="table-placeholder">
              {{'no_debtors_in_overview'|translate|ucFirst}}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="filteredDebtorCount">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">group</mat-icon>
          {{'contacts'|translate|ucFirst}}
          <span *ngIf="!this.searchTerm">&nbsp;({{this.contactsCount}})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{this.allFilteredData['contacts'].length}}/{{this.contactsCount}})
          </span>
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="filteredDebtors.length > 0">
          <tr *ngFor="let item of filteredDebtors" td-data-table-row>
            <td (click)="(c.name!='tools' ? open(item.id) : false)" *ngFor="let c of columns" [numeric]="item.numeric"
                class="select {{(c.name=='tools'?'text-right':'')}}"
                td-data-table-cell>
            <span [ngSwitch]="c.name">
              <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                *ngIf="!item[c.name]">-</span></span>
            </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="filteredDebtors.length === 0">
          <tr>
            <td [colSpan]="columns.length"
                class="table-placeholder">
              {{'no_debtors_in_overview'|translate|ucFirst}}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="filteredDebtorCount">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
