import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { ucFirst } from '../pipes/uc-first.pipe';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { CoolLocalStorage } from '@angular-cool/storage';
import { UtilityService } from './utility.service';

@Injectable()
export class ExporterService extends GlobalService {

  constructor(
    _http: HttpClient,
    _vault: CoolLocalStorage,
    _utilityService: UtilityService
  ) {
    super(_http, _vault, _utilityService);
    this.setModel('exports');
  }

  async generate(fileName: string, translations: any[], sheets: any[], colWidths?: any[]): Promise<void> {
    const workbook = new ExcelJS.Workbook();

    for (const sheet of sheets) {
      const worksheet = workbook.addWorksheet(ucFirst(`${translations[sheet.title] || sheet.title}`));

      // Check if headers should be set (skip for sheets like `job_invoices` that have custom headers)
      if (sheet.headers && sheet.headers.length > 0 && !sheet.rowHeaders) {
        const headerRow = worksheet.addRow(sheet.headers.map(header => header.label));

        // Apply bold styling to header cells if they contain "|bold"
        headerRow.eachCell((cell, colNumber) => {
          const header = sheet.headers[colNumber - 1];
          const headerValue = ucFirst((translations[header.label] ? translations[header.label] : header.label));
          cell.font = { bold: true }; // Apply bold style
          cell.value = headerValue; // Set the cleaned header value
        });
      }

      // Process rows (including applying bold where "|bold" exists)
      sheet.rows.forEach(row => {
        // Prepare row data based on values in sheet.rows
        const rowData = Object.values(row).map(value => {
          let cellValue = value;
          let isBold = false;

          // Check if the cell value has the "|bold" marker
          if (typeof cellValue === 'string' && cellValue.includes('|bold')) {
            const [text, style] = cellValue.split('|');
            cellValue = text; // Remove the "|bold" marker
            if (style === 'bold') {
              isBold = true;
            }
          }

          return { value: cellValue, isBold };
        });

        // Add the row data to the worksheet and apply bold style where needed
        const newRow = worksheet.addRow(rowData.map(cell => cell.value));

        // Apply bold style to cells where necessary
        newRow.eachCell((cell, colNumber) => {
          const cellData = rowData[colNumber - 1];
          if (cellData.isBold) {
            cell.font = { bold: true }; // Apply bold style
          }
        });
      });

      // Set column widths if provided
      if (sheet.colWidths) {
        sheet.colWidths.forEach((width, index) => {
          worksheet.getColumn(index + 1).width = width.wch;
        });
      }

      // Set column widths if provided
      if (sheet.colFormats) {
        sheet.colFormats.forEach((format, index) => {
          worksheet.getColumn(index + 1).numFmt  = format;
        });
      }
    }

    // Write to file (in memory)
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer and trigger download
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`; // Set the file name
    link.click();
  }
}
